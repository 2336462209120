import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import { UUID } from 'angular2-uuid';
import { TimeRounding } from '../app/shared/enums';
import { Client } from '../app/models/Client';

// dayjs plugins
dayjs.extend(dayjsDuration);

/**
 * Returns a duration as miliseconds
 * 
 * @param startTime
 * @param endTime
 * @param timeRoundingRule
 * @returns milliseconds
 */
export default function roundDuration(
  startTime: dayjs.Dayjs,
  endTime: dayjs.Dayjs,
  timeRoundingRule: TimeRounding
): dayjsDuration.Duration {
  const duration = dayjs.duration(endTime.diff(startTime));
  if (duration.asSeconds() < 0) {
    return;
  }
  let remainder = 0;

  switch (timeRoundingRule) {
    case TimeRounding.ToNearestMinute: {
      remainder = duration.asSeconds() % 60;
      if (remainder >= (60 / 2)) {
        duration.add(60 - remainder, 'seconds');
      } else {
        duration.add(-remainder, 'seconds');
      }
      break;
    }
    case TimeRounding.ToNearestQuarterHour: {
      remainder = duration.asMinutes() % (60 / 4);
      if (remainder >= (60 / 4 / 2)) {
        duration.add((60 / 4)  - remainder, 'minutes');
      } else {
        duration.add(-remainder, 'minutes');
      }
      break;
    }
    case TimeRounding.ToNearestHalfHour: {
      remainder = duration.asMinutes() % (60 / 2);
      if (remainder >= (60 / 2 / 2)) {
        duration.add((60 / 2)  - remainder, 'minutes');
      } else {
        duration.add(-remainder, 'minutes');
      }
      break;
    }
    case TimeRounding.ToNearestHour: {
      remainder = duration.asMinutes() % 60;
      if (remainder >= (60 / 2)) {
        duration.add(60 - remainder, 'minutes');
      } else {
        duration.add(-remainder, 'minutes');
      }
      break;
    }
    case TimeRounding.UpToNearestMinute: {
      remainder = duration.asSeconds() % 60;
      if (remainder) {
        duration.add(-1 * remainder, 'seconds');
        duration.add(1, 'minute');
      } else if (!remainder && duration.asSeconds() === 0) {
        duration.add(1, 'minute');
      }
      break;
    }
    case TimeRounding.UpToNearestQuarterHour: {
      remainder = duration.asMinutes() % (60 / 4);
      if (remainder) {
        duration.add(-1 * remainder, 'minutes');
        duration.add(15, 'minutes');
      } else if (!remainder && duration.asMinutes() === 0) {
        duration.add(15, 'minutes');
      }
      break;
    }
    case TimeRounding.UpToNearestHalfHour: {
      remainder = duration.asMinutes() % (60 / 2);
      if (remainder) {
        duration.add(-1 * remainder, 'minutes');
        duration.add(30, 'minutes');
      } else if (!remainder && duration.asMinutes() === 0) {
        duration.add(30, 'minutes');
      }
      break;
    }
    case TimeRounding.UpToNearestHour: {
      remainder = duration.asMinutes() % 60;
      duration.add(-1 * remainder, 'minutes');
      if (remainder > 0) {
        duration.add(1, 'hour');
      }
      break;
    }
    case TimeRounding.DownToNearestMinute: {
      remainder = duration.asSeconds() % 60;
      if (remainder) {
        duration.add(-1 * remainder, 'seconds');
      }
      break;
    }
    case TimeRounding.DownToNearestQuarterHour: {
      remainder = duration.asMinutes() % (60 / 4);
      if (remainder) {
        duration.add(-1 * remainder, 'minutes');
      }
      break;
    }
    case TimeRounding.DownToNearestHalfHour: {
      remainder = duration.asMinutes() % 60;
      duration.add(-1 * remainder, 'minutes');
      if (remainder >= 30) {
        duration.add(30, 'minutes');
      }
      break;
    }
    case TimeRounding.DownToNearestHour: {
      remainder = duration.asMinutes() % 60;
      if (remainder) {
        duration.add(-1 * remainder, 'minutes');
      }
      break;
    }
    default:
      return;
  }
  return duration;
}

export function getRoundingRule(selectedClients: Client[], clientId: UUID): TimeRounding {
  let roundingRule: any;
  // handles the case where a rounding rule doesn't exist
  roundingRule = TimeRounding.ToNearestMinute;
  if (selectedClients && selectedClients.length > 0) {
    return selectedClients.find(s => s.clientID === clientId && s.timeRounding)?.timeRounding;
  }
  return roundingRule;
}
